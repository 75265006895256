import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/demo',
        name: 'Demo',
        component: () => import('@/views/Demo.vue'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/banking',
        name: 'Banking',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/banking/transaction',
        children: [
          {
            path: '/banking/transaction/:tab?',
            name: 'Transaction',
            component: () => import('@/views/_banking/Transaction.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/banking/transfer',
            name: 'Transfer',
            component: () => import('@/views/_banking/Transfer.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/banking/return-credit',
            name: 'Return Credit',
            component: () => import('@/views/_banking/ReturnCredit.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/member',
        name: 'Member',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/member/list',
        children: [
          {
            path: '/member/create',
            name: 'Create Member',
            component: () => import('@/views/_member/Create.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/member/list',
            name: 'Lists of member',
            component: () => import('@/views/_member/Lists.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/member/list/:webSite/:memberID',
            name: 'Member ID',
            component: () => import('@/views/_member/ManageMember.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/member/list-new',
            name: 'Lists of new member',
            component: () => import('@/views/_member/New.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/member/blacklist',
            name: 'Black List',
            component: () => import('@/views/_member/BlackList.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/setting',
        name: 'Setting',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/setting/general',
        children: [
          {
            path: '/setting/general',
            name: 'General',
            component: () => import('@/views/_setting/system/General.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/setting/banking-acct',
            name: 'Bank Account',
            component: () => import('@/views/_setting/system/BankingAcc.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/setting/sms-system',
            name: 'SMS System',
            component: () => import('@/views/_setting/system/SMSSystem.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/setting/games-category',
            name: 'Games Setting - Category',
            component: () => import('@/views/_setting/games/Category.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/setting/games-provider',
            name: 'Games Setting - Provider',
            component: () => import('@/views/_setting/games/Provider.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/setting/games-games',
            name: 'Games Setting - Games',
            component: () => import('@/views/_setting/games/Games.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/setting/front-end',
            name: 'Front-end',
            component: () => import('@/views/_setting/system/FrontEnd.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/setting/security',
            name: 'Security',
            component: () => import('@/views/_setting/system/Security.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/setting/agent',
            name: 'Agent',
            component: () => import('@/views/_setting/system/Agent.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/setting/balck-list',
            name: 'Balck list',
            component: () => import('@/views/_setting/system/BalckList.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/setting/website/:tab?',
            name: 'Website Setting',
            component: () => import('@/views/_setting/web/Website.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/promotion',
        name: 'Promotion',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/promotion/promotions',
        children: [
          {
            path: '/promotion/promotions',
            name: 'Promotion',
            component: () =>
              import('@/views/_setting/promotion/Promotions.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/promotion/daily-check-in',
            name: 'Daily check-in',
            component: () =>
              import('@/views/_setting/promotion/DailyCheckIn.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/promotion/create/:type?',
            name: 'Create Promotion',
            component: () => import('@/views/_setting/promotion/Create.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/promotion/clone/:promotionID?',
            name: 'Clone Promotion',
            component: () => import('@/views/_setting/promotion/Create.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/promotion/management/:action/:promotion',
            name: 'Manage Promotion',
            component: () =>
              import('@/views/_setting/promotion/ManagePromotion.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/promotion/coupon/:promotion',
            name: 'Coupon History',
            component: () =>
              import('@/views/_setting/promotion/CouponHistory.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/promotion/search',
            name: 'Search Promotion',
            component: () =>
              import('@/views/_setting/promotion/SearchPromotion.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/setting/employee',
        name: 'Employees',
        component: () => import('@/views/_setting/employee/Employee.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/reports',
        name: 'Reports',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/reports/daily',
        children: [
          {
            path: '/reports/daily',
            name: 'Daily report',
            component: () => import('@/views/_report/Daily.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/reports/minigames',
            name: 'Mini games report',
            component: () => import('@/views/_report/MiniGames.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/reports/partner',
            name: 'Partner report',
            component: () => import('@/views/_report/Partner.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/reports/monthly',
            name: 'Monthly report',
            component: () => import('@/views/_report/Monthly.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/reports/compare',
            name: 'Compare report',
            component: () => import('@/views/_report/Compare.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/commission',
        name: 'Commission',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/commission/setting',
        children: [
          {
            path: '/commission/setting',
            name: 'Commission Setting',
            component: () => import('@/views/_commission/Setting.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/partner',
        name: 'Partner',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/partner/dashboard',
        children: [
          {
            path: '/partner/dashboard',
            name: 'Partner Dashboard',
            component: () => import('@/views/_partner/Dashboard.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/partner/userinfo',
            name: 'Partner UserInfo',
            component: () => import('@/views/_partner/UserInfo.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/partner/promotion',
            name: 'Partner Promotion',
            component: () => import('@/views/_partner/Promotion.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/partner/commission/:teamID?/:date?',
            name: 'Partner Commission',
            component: () => import('@/views/_partner/Commission.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/partner/setting/group',
            name: 'Partner Setting - Group',
            component: () => import('@/views/_partner/SettingGroup.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/partner/setting/partner',
            name: 'Partner Setting - Partner',
            component: () => import('@/views/_partner/SettingPartner.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/partner/staff',
            name: 'Partner Staff',
            component: () => import('@/views/_partner/Staff.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/minigames',
        name: 'Minigames',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/minigames/setting',
        children: [
          {
            path: '/minigames/setting/:tab?',
            name: 'Mini Games Setting',
            component: () => import('@/views/_minigames/Setting.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/minigames/report',
            name: 'Mini Games Report',
            component: () => import('@/views/_minigames/Report.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/report',
        name: 'Report',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/report/dashboard',
        children: [
          {
            path: '/report/dashboard',
            name: 'Report',
            component: () => import('@/views/_partner/Dashboard.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/widgets',
        name: 'Widgets',
        component: () => import('@/views/widgets/Widgets.vue'),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
        meta: { guest: true },
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
        meta: { guest: true },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/authenticated'] != null) {
      next()
      return
    }
    next('/pages/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters['auth/authenticated'] != null) {
      next('/')
      return
    }
    next()
  } else {
    next()
  }
})

export default router
