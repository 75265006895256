import axios from 'axios'

const authAxios = axios.create({
  // baseURL: 'https://api.taetrueteam.fun',
  baseURL: 'https://api.ttt-project.com',
  timeout: 60000,
})

const mainAxios = axios.create({
  // baseURL: 'https://api.taetrueteam.fun',
  baseURL: 'https://api.ttt-project.com',
  timeout: 20000,
})

const ipInfo = axios.create({
  baseURL: 'https://ipinfo.io/json?token=203c6230ece96c',
  timeout: 5000,
})

const scbTag = axios.create({
  baseURL: 'http://info-msisdn.scb.co.th:8080/msisdn',
  timeout: 5000,
})

export { mainAxios, authAxios, ipInfo, scbTag }
