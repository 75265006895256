// admin, owner, manager, staff, partner, headless
export default [
  {
    component: 'CNavItem',
    name: 'แดชบอร์ด',
    to: '/dashboard',
    icon: 'cil-speedometer',
    role: ['admin', 'owner', 'manager', 'staff'],
  },
  {
    component: 'CNavTitle',
    name: 'ข้อมูลการเงิน',
    role: ['admin', 'owner', 'manager', 'staff', 'headless'],
  },
  {
    component: 'CNavItem',
    name: 'ฝาก - ถอน',
    to: '/banking/transaction',
    icon: 'cil-institution',
    role: ['admin', 'owner', 'manager', 'staff'],
  },
  {
    component: 'CNavItem',
    name: 'คืนยอดเสีย',
    to: '/banking/return-credit',
    icon: 'cil-balance-scale',
    role: ['admin', 'owner', 'manager', 'staff', 'headless'],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'โอนมือ',
  //   to: '/banking/transfer',
  //   icon: 'cil-braille',
  // },
  {
    component: 'CNavTitle',
    name: 'ข้อมูลลูกค้า',
    role: ['admin', 'owner', 'manager', 'staff'],
  },
  {
    component: 'CNavItem',
    name: 'สร้างลูกค้าใหม่',
    to: '/member/create',
    icon: 'cil-user-plus',
    role: ['admin', 'owner', 'manager', 'staff'],
  },
  {
    component: 'CNavItem',
    name: 'รายชื่อลูกค้า',
    to: '/member/list',
    icon: 'cil-group',
    role: ['admin', 'owner', 'manager', 'staff'],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'รายชื่อลูกค้าเปิดใหม่',
  //   to: '/member/list-new',
  //   icon: 'cil-child',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'แบลคลิส',
  //   to: '/member/blacklist',
  //   icon: 'cil-thumb-down',
  // },
  {
    component: 'CNavTitle',
    name: 'โปรโมชั่น',
    role: ['admin', 'owner', 'manager', 'staff'],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'สร้างโปรโมชั่น',
  //   to: '/promotion/create',
  //   icon: 'cil-playlist-add',
  // },
  {
    component: 'CNavItem',
    name: 'เช็คอินรายวัน',
    to: '/promotion/daily-check-in',
    icon: 'cil-calendar-check',
    role: ['admin', 'owner', 'manager', 'staff'],
  },
  {
    component: 'CNavItem',
    name: 'รายการโปรโมชั่น',
    to: '/promotion/promotions',
    icon: 'cil-gift',
    role: ['admin', 'owner', 'manager', 'staff'],
  },
  {
    component: 'CNavItem',
    name: 'ค้นหา',
    to: '/promotion/search',
    icon: 'cil-search',
    role: ['admin', 'owner', 'manager', 'staff'],
  },
  //
  //
  //
  {
    component: 'CNavTitle',
    name: 'มินิเกม',
    role: ['admin', 'owner', 'manager', 'staff'],
  },
  {
    component: 'CNavItem',
    name: 'การตั้งค่า',
    to: '/minigames/setting',
    icon: 'cil-settings',
    role: ['admin', 'owner', 'manager', 'staff'],
  },
  {
    component: 'CNavItem',
    name: 'รายงาน',
    to: '/minigames/report',
    icon: 'cil-description',
    role: ['admin', 'owner', 'manager', 'staff'],
  },
  //
  //
  //
  {
    component: 'CNavTitle',
    name: 'การตั้งค่า',
    role: ['admin', 'owner', 'manager'],
  },
  {
    component: 'CNavGroup',
    name: 'ระบบ',
    to: '/setting',
    icon: 'cil-settings',
    role: ['admin', 'owner', 'manager'],
    items: [
      {
        component: 'CNavItem',
        name: 'ทั่วไป',
        to: '/setting/general',
        role: ['admin', 'owner'],
      },
      {
        component: 'CNavItem',
        name: 'บัญชีเว็บ',
        to: '/setting/banking-acct',
        role: ['admin', 'owner', 'manager'],
      },
      {
        component: 'CNavItem',
        name: 'ระบบข้อความ',
        to: '/setting/sms-system',
        role: ['admin', 'owner'],
      },
      {
        component: 'CNavItem',
        name: 'เว็บไซต์',
        to: '/setting/website',
        role: ['admin', 'owner'],
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'พนักงาน',
    to: '/setting/employee',
    icon: 'cil-contact',
    role: ['admin', 'owner'],
  },
  {
    component: 'CNavTitle',
    name: 'รายงาน',
    role: ['admin', 'owner', 'manager', 'staff'],
  },
  {
    component: 'CNavGroup',
    name: 'รายงาน',
    to: '/reports',
    icon: 'cil-folder-open',
    role: ['admin', 'owner', 'manager', 'staff'],
    items: [
      {
        component: 'CNavItem',
        name: 'Daily',
        to: '/reports/daily',
        role: ['admin', 'owner', 'manager', 'staff'],
      },
      {
        component: 'CNavItem',
        name: 'Mini games',
        to: '/reports/minigames',
        role: ['admin', 'owner', 'manager', 'staff'],
      },
      {
        component: 'CNavItem',
        name: 'Partner',
        to: '/reports/partner',
        role: ['admin', 'owner', 'manager', 'staff'],
      },
      {
        component: 'CNavItem',
        name: 'Monthly',
        to: '/reports/monthly',
        role: ['admin', 'owner', 'manager', 'staff'],
      },
      {
        component: 'CNavItem',
        name: 'Compare',
        to: '/reports/compare',
        role: ['admin', 'owner', 'manager', 'staff'],
      },
    ],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'นำออกเอกสาร',
  //   to: '/report/export',
  //   icon: 'cil-cloud-download',
  // },

  // Commission
  {
    component: 'CNavTitle',
    name: 'คอมมิชชั่น',
    role: ['admin', 'owner'],
  },
  {
    component: 'CNavItem',
    name: 'การตั้งค่า',
    to: '/commission/setting',
    icon: 'cil-settings',
    role: ['admin', 'owner'],
  },

  // พาร์ทเนอร์
  {
    component: 'CNavTitle',
    name: 'พาร์ทเนอร์',
    role: ['admin', 'owner'],
  },
  {
    component: 'CNavItem',
    name: 'ทั่วไป',
    to: '/partner/dashboard',
    icon: 'cil-av-timer',
    role: ['admin', 'owner', 'partner', 'headmarketing'],
  },
  {
    component: 'CNavItem',
    name: 'ข้อมูลลูกค้า',
    to: '/partner/userinfo',
    icon: 'cil-group',
    role: ['partner', 'headmarketing'],
  },
  {
    component: 'CNavItem',
    name: 'โปรโมชั่น',
    to: '/partner/promotion',
    icon: 'cil-gift',
    role: ['admin', 'owner', 'partner', 'headmarketing'],
  },
  {
    component: 'CNavItem',
    name: 'คอมมิชชั่น',
    to: '/partner/commission',
    icon: 'cil-chart-line',
    role: ['admin', 'owner', 'partner', 'headmarketing'],
  },
  {
    component: 'CNavItem',
    name: 'ตั้งค่ากลุ่ม',
    to: '/partner/setting/group',
    icon: 'cil-lan',
    role: ['admin', 'owner'],
  },
  {
    component: 'CNavItem',
    name: 'ตั้งค่าพาร์ทเนอร์',
    to: '/partner/setting/partner',
    icon: 'cil-settings',
    role: ['admin', 'owner', 'headmarketing'],
  },
  {
    component: 'CNavGroup',
    name: 'การตั้งค่า',
    to: '/partner',
    icon: 'cil-settings',
    role: ['admin', 'partner'],
    items: [
      {
        component: 'CNavItem',
        name: 'ทั่วไป',
        to: '/partner/setting/partner',
        role: ['admin', 'partner'],
      },
      {
        component: 'CNavItem',
        name: 'พนักงาน',
        to: '/partner/staff',
        role: ['admin', 'partner'],
      },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
